import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button, Divider } from "@mui/material";
//import Header from "../components/Header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import BBPS from "../assets/BBPS.png"; // Use BBPS.png instead of Logo.png

const TestPlanGeneratorV2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { TestPlanGeneratorV2: apiResponse } = location.state || {};
  const testData = apiResponse?.data;

  const formatMCQOptions = (question) => {
    const [questionText, optionsText] = question.split("Options:");
    if (!optionsText) return { questionText: question, options: [] };
    const options = optionsText.match(/[A-D]\)(.*?)(?=[A-D]\)|$)/g) || [];
    return {
      questionText: questionText.trim(),
      options: options.map((opt) => opt.trim()),
    };
  };

  const handleDownloadPDF = () => {
    // Initialize PDF with better formatting
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    // Set initial positions and spacing
    let startY = 20;
    const margin = 20;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const contentWidth = pageWidth - 2 * margin;

    // Helper function to add new page
    const addNewPage = () => {
      doc.addPage();
      startY = 20;
      return startY;
    };

    // Helper function to check and add new page if needed
    const checkAndAddPage = (height) => {
      if (startY + height > pageHeight - margin) {
        return addNewPage();
      }
      return startY;
    };

    // Add BBPS logo to PDF
    const imgWidth = 180;
    const imgHeight = 45; // Set the desired height
    doc.addImage(BBPS, "PNG", margin, 10, imgWidth, imgHeight);
    startY += imgHeight + 10;

    // Title
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Generated Exam Paper", margin, startY);
    startY += 10;

    // General Instructions
    if (testData.General_Instructions) {
      startY = checkAndAddPage(10);
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("General Instructions:", margin, startY);
      doc.setFont("helvetica", "normal");
      startY += 7;

      // Ensure testData.General_Instructions is treated as an array
      let instructions = Array.isArray(testData.General_Instructions)
        ? testData.General_Instructions
        : testData.General_Instructions.split("\n").filter(
            (line) => line.trim() !== ""
          );

      instructions.forEach((instruction) => {
        const splitText = doc.splitTextToSize(instruction.trim(), contentWidth);

        // Print each line properly
        splitText.forEach((line) => {
          startY = checkAndAddPage(7);
          doc.text(line, margin, startY);
          startY += 7;
        });

        startY += 3; // Space between instructions
      });

      startY += 5;
    }

    // MCQs Section
    if (testData.MCQs) {
      startY = checkAndAddPage(10);
      doc.setFont("helvetica", "bold");
      doc.text("Multiple Choice Questions", margin, startY);
      doc.setFont("helvetica", "normal");
      startY += 7;

      testData.MCQs.forEach((questions) => {
        Object.entries(questions[0]).forEach(([questionNo, question]) => {
          const { questionText, options } = formatMCQOptions(question);

          // Format question text
          const questionLines = doc.splitTextToSize(
            `${questionNo}. ${questionText}`,
            contentWidth
          );
          startY = checkAndAddPage(
            questionLines.length * 7 + options.length * 7 + 5
          );
          doc.text(questionLines, margin, startY);
          startY += questionLines.length * 7;

          // Format options
          options.forEach((option) => {
            const optionLines = doc.splitTextToSize(option, contentWidth - 10);
            startY = checkAndAddPage(optionLines.length * 7);
            doc.text(optionLines, margin + 10, startY);
            startY += optionLines.length * 7;
          });
          startY += 5;
        });
      });
    }

    // Other sections
    const sections = [
      { key: "True_False_Questions", title: "True/False Questions" },
      { key: "Fill_in_the_Blanks", title: "Fill in the Blanks" },
      { key: "Very_Short_Answers", title: "Very Short Answers" },
      { key: "Short_Answers", title: "Short Answers" },
      { key: "Long_Answers", title: "Long Answers" },
      { key: "Very_Long_Answers", title: "Very Long Answers" },
      { key: "Case_Studies", title: "Case Studies" },
    ];

    sections.forEach(({ key, title }) => {
      if (testData[key] && testData[key].length > 0) {
        startY = checkAndAddPage(10);
        doc.setFont("helvetica", "bold");
        doc.text(title, margin, startY);
        doc.setFont("helvetica", "normal");
        startY += 7;

        testData[key].forEach((questions) => {
          Object.entries(questions[0]).forEach(([questionNo, question]) => {
            const lines = doc.splitTextToSize(
              `${questionNo}. ${question}`,
              contentWidth
            );
            startY = checkAndAddPage(lines.length * 7);
            doc.text(lines, margin, startY);
            startY += lines.length * 7 + 3;
          });
        });
        startY += 5;
      }
    });

    // Answers Section
    if (testData.Answers) {
      startY = checkAndAddPage(10);
      doc.setFont("helvetica", "bold");
      doc.text("Answers", margin, startY);
      doc.setFont("helvetica", "normal");
      startY += 7;

      Object.entries(testData.Answers).forEach(([key, answer]) => {
        const lines = doc.splitTextToSize(`${key}. ${answer}`, contentWidth);
        startY = checkAndAddPage(lines.length * 7);
        doc.text(lines, margin, startY);
        startY += lines.length * 7;
      });
    }

    doc.save("Generated_Exam_Paper.pdf");
  };

  if (!testData) {
    return (
      <>
        {/* <Header /> */}
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <img
            src={BBPS}
            alt="BBPS Logo"
            style={{ width: "fit-content", height: "190px" }}
          />
        </Box>
        <Box sx={{ p: 2, pt: 10 }}>
          <Typography>No test data available</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ p: 4, pt: 4, px: 24, fontFamily: "Arial, sans-serif" }}>
        {/* Header Image - BBPS logo */}
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <img
            src={BBPS}
            alt="BBPS Logo"
            style={{ width: "fit-content", height: "190px" }}
          />
        </Box>

        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Generated Exam Paper
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          General Instructions
        </Typography>
        <Box sx={{ mb: 3 }}>
          {testData.General_Instructions &&
            testData.General_Instructions.split("\n").map(
              (instruction, index) => <p key={index}>{instruction.trim()}</p>
            )}
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* MCQs with vertical options */}
        {testData.MCQs && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              MCQs
            </Typography>
            {testData.MCQs.map((questions) =>
              Object.entries(questions[0]).map(([questionNo, question]) => {
                const { questionText, options } = formatMCQOptions(question);
                return (
                  <Box key={questionNo} sx={{ ml: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      {questionNo}. {questionText}
                    </Typography>
                    {options.map((option, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        sx={{ ml: 3, mb: 0.5 }}
                      >
                        {option}
                      </Typography>
                    ))}
                  </Box>
                );
              })
            )}
          </Box>
        )}

        {/* Other sections */}
        {[
          {
            title: "True/False Questions",
            data: testData.True_False_Questions,
          },
          { title: "Fill in the Blanks", data: testData.Fill_in_the_Blanks },
          { title: "Very Short Answers", data: testData.Very_Short_Answers },
          { title: "Short Answers", data: testData.Short_Answers },
          { title: "Long Answers", data: testData.Long_Answers },
          { title: "Very Long Answers", data: testData.Very_Long_Answers },
          { title: "Case Studies", data: testData.Case_Studies },
        ].map(
          ({ title, data }) =>
            data &&
            data.length > 0 && (
              <Box key={title} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  {title}
                </Typography>
                {data.map((questions) =>
                  Object.entries(questions[0]).map(([questionNo, question]) => (
                    <Box key={questionNo} sx={{ ml: 2, mb: 2 }}>
                      <Typography variant="body1">
                        {questionNo}. {question}
                      </Typography>
                    </Box>
                  ))
                )}
              </Box>
            )
        )}

        {/* Adding Answers Section */}
        {testData.Answers && Object.keys(testData.Answers).length > 0 && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Answers
            </Typography>
            {Object.entries(testData.Answers).map(([key, answer]) => (
              <Box key={key} sx={{ ml: 2, mb: 1 }}>
                <Typography variant="body1">
                  {key}. {answer}
                </Typography>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/")}
            sx={{ mt: 4 }}
          >
            Go to Home
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
            sx={{ mt: 4 }}
          >
            Download PDF
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TestPlanGeneratorV2;

import React from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//import Header from "./Header"; // Ensure this path is correct
import ReactMarkdown from "react-markdown";
import BBPS from "../assets/BBPS.png"

// Import the images
import Generate_Curriculum from "../assets/Generate_Curriculum.png";
import Recommended_Pace from "../assets/Recommended_Pace.png";
import Propose_Subjects from "../assets/Propose_Subjects.png";
import Propose_Courses from "../assets/Propose_Courses.png";

const Result = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const quizResult = location.state?.quizResult || "No result available.";

  // Split the quiz result into sections
  const sections = quizResult
    .split(/\n(?=\*\*)/)
    .map((section) => section.trim());

  // Calculate 25% of the sections
  const leftCount = Math.ceil(sections.length * 0.25); // 25% of total sections
  const leftSections = sections.slice(0, leftCount); // First 25% for the left column
  const rightSections = sections.slice(leftCount); // Remaining 75% for the right column

  // Menu items with icons
  const menuItems = [
    {
      text: "Generate curriculum",
      path: "/generate-curriculum",
      icon: Generate_Curriculum,
    },
    {
      text: "Recommended pace",
      path: "/recommended-pace",
      icon: Recommended_Pace,
    },
    {
      text: "Propose Subjects",
      path: "/propose-subjects",
      icon: Propose_Subjects,
    },
    {
      text: "Propose courses",
      path: "/propose-courses",
      icon: Propose_Courses,
    },
  ];

  return (
    <>
      {/* <Header /> */}
      {/* BBPS Logo at the top */}
      <Box sx={{ backgroundColor: "#FEFBF4", minHeight: "100vh", paddingY: 4 }}>
        <Container maxWidth="lg">
          <Paper
            elevation={3}
            sx={{ padding: 4, borderRadius: 2, backgroundColor: "white" }}
          >
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <img
                src={BBPS}
                alt="BBPS Logo"
                style={{ width: "100%", maxWidth: "800px", height: "auto" }}
              />
            </Box>
            <Typography variant="h4" gutterBottom>
              Assessment Result
            </Typography>
            <CheckCircleIcon
              sx={{ fontSize: 60, color: "green", marginBottom: 2 }}
            />

            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                {" "}
                {/* Left Section (25%) */}
                {/* Left Section with Quiz Sections and Menu Items */}
                {leftSections.map((section, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: 2,
                      borderRadius: 2,
                      backgroundColor: "white", // White background for boxes
                      marginBottom: 2,
                    }}
                  >
                    <Typography variant="body1">
                      <ReactMarkdown>{section}</ReactMarkdown>
                    </Typography>
                  </Box>
                ))}
                {/* Menu Items on the Left */}
                <List>
                  {menuItems.map((item, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={() => navigate(item.path)} // Navigate to respective path
                      sx={{
                        backgroundColor: "white",
                        marginBottom: 1,
                        borderRadius: 2,
                        "&:hover": { backgroundColor: "#f0f0f0" },
                      }}
                    >
                      <ListItemIcon>
                        <Box
                          component="img"
                          src={item.icon}
                          alt={item.text}
                          sx={{ width: 24, height: 24 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                      <ArrowForwardIosIcon sx={{ color: "#714B90" }} />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid item xs={12} md={8}>
                {" "}
                {/* Right Section (75%) */}
                {rightSections.map((section, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: 2,
                      borderRadius: 2,
                      backgroundColor: "white", // White background for boxes
                      marginBottom: 2,
                    }}
                  >
                    <Typography variant="body1">
                      <ReactMarkdown>{section}</ReactMarkdown>
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>

            <Box mt={4} textAlign="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#714B90",
                  ":hover": { backgroundColor: "#5e3d74" },
                }}
                onClick={() => navigate("/")} // Adjust this path to your home page
              >
                Home Page
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default Result;

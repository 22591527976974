import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import html2pdf from "html2pdf.js"; // Import html2pdf
import BBPS from "../assets/BBPS.png"; // Adjust the path to the BBPS logo

const LessonPlanGeneratorV2 = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const contentToExportRef = useRef(); // Reference to the HTML content for exporting as PDF
  const [lessonPlan, setLessonPlan] = useState(""); // State to store the current lesson plan

  useEffect(() => {
    // Set the lesson plan when it changes from the location state
    if (location.state?.lessonPlan) {
      setLessonPlan(location.state.lessonPlan);
    }
  }, [location.state?.lessonPlan]); // Only re-run this effect when the lessonPlan changes

  // Function to navigate to the home page
  const goToHome = () => {
    navigate("/"); // Use navigate('/') to go to the home page
  };

  // Function to export the lesson plan as PDF using html2pdf
  const exportAsPDF = () => {
    const element = contentToExportRef.current.cloneNode(true); // Clone the content
  
    // Dynamically add the BBPS logo to the top of the content
    const bbpsLogoElement = document.createElement("img");
    bbpsLogoElement.src = BBPS;
    bbpsLogoElement.alt = "BBPS Logo";
    bbpsLogoElement.style.height = "150px"; // Set the height of the logo
    bbpsLogoElement.style.width = "725px"; // Set width to auto to maintain the aspect ratio
    bbpsLogoElement.style.marginBottom = "20px";
  
    // Insert the BBPS logo at the top of the cloned content
    element.prepend(bbpsLogoElement);

    const options = {
      margin: 0.5,
      filename: "lesson_plan.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Export the cloned content as a PDF
    html2pdf()
      .from(element)
      .set(options)
      .save();
  };

  // Markdown link transformation
  const transformLink = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `[${url}](${url})`);
  };

  // Custom renderer for links to open them in a new tab
  const renderers = {
    a: ({ href, children }) => (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  };

  return (
    <div style={{ backgroundColor: "#FEFBF4", minHeight: "100vh", paddingLeft: "170px", paddingRight: "170px" }}>
      {/* BBPS logo at the top of the page */}
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img src={BBPS} alt="BBPS Logo" style={{ height: "190px", width: "fit-content" }} />
      </div>

      {/* Lesson Plan Content - ONLY this part will be exported */}
      <div ref={contentToExportRef}>
        {lessonPlan ? (
          <ReactMarkdown rehypePlugins={[rehypeRaw]} components={renderers}>
            {transformLink(lessonPlan)}
          </ReactMarkdown>
        ) : (
          <p>No lesson plan generated yet.</p>
        )}
      </div>

      {/* Buttons - NOT INCLUDED IN PDF */}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <button onClick={goToHome} style={buttonStyle}>Go to Home</button>
        <button onClick={exportAsPDF} style={buttonStyle}>Export as PDF</button>
      </div>
    </div>
  );
};

// Styling for buttons
const buttonStyle = {
  backgroundColor: "#007BFF",
  color: "#fff",
  border: "none",
  padding: "10px 20px",
  marginRight: "10px",
  cursor: "pointer",
  borderRadius: "4px",
  display: "inline-block",
  textAlign: "center",
  fontSize: "16px",
  height: "40px",
  lineHeight: "20px",
};

export default LessonPlanGeneratorV2;

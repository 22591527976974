import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import UploadIcon from "@mui/icons-material/UploadFile"; // Material-UI upload icon
import DeleteIcon from "@mui/icons-material/Delete";

const Modal6 = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [board, setBoard] = useState("");
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [testDuration, setTestDuration] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [topics, setTopics] = useState([]);
  const [questionMarks, setQuestionMarks] = useState({
    marks_of_each_mcqs: 1,
    marks_of_each_very_short_answers: 1,
    marks_of_each_short_answer: 2,
    marks_of_each_long_answer: 3,
    marks_of_each_very_long_answer: 4,
    marks_of_each_case_studies: 5,
    marks_of_each_truth_False_questions: 1,
    marks_of_each_fill_in_the_blanks: 1,
  });
  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]); // Array for chapters added by user
  const [availableChapters, setAvailableChapters] = useState([]); // Array for chapters from API
  const [totalAssignedMarks, setTotalAssignedMarks] = useState(0);
  const [chapterAllocatedMarks, setChapterAllocatedMarks] = useState({});
  const [topicAllocatedMarks, setTopicAllocatedMarks] = useState({});

  // Reset function to set everything back to initial state
  const resetModal = () => {
    setLoading(false);
    setBoard("");
    setGrade("");
    setSubject("");
    setTestDuration("");
    setDifficulty("");
    setTotalMarks("");
    setTopics([]);
    setQuestionMarks({
      marks_of_each_mcqs: 1,
      marks_of_each_very_short_answers: 1,
      marks_of_each_short_answer: 2,
      marks_of_each_long_answer: 3,
      marks_of_each_very_long_answer: 4,
      marks_of_each_case_studies: 5,
      marks_of_each_truth_False_questions: 1,
      marks_of_each_fill_in_the_blanks: 1,
    });
    setGrades([]);
    setSubjects([]);
    setSelectedChapters([]);
    setAvailableChapters([]);
    setTotalAssignedMarks(0);
    setChapterAllocatedMarks({});
    setTopicAllocatedMarks({});
  };

  // Reset modal when it opens
  useEffect(() => {
    if (open) {
      resetModal();
      // Fetch boards again when modal opens
      const fetchBoards = async () => {
        try {
          const response = await axios.get(
            "https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values"
          );
          if (response.data.status) {
            setBoards(response.data.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching boards:", error);
        }
      };
      fetchBoards();
    }
  }, [open]);

  useEffect(() => {
    if (board) {
      const fetchGrades = async () => {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}`
          );
          if (response.data.status) {
            setGrades(response.data.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGrades();
    }
  }, [board]);

  useEffect(() => {
    if (board && grade) {
      const fetchSubjects = async () => {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}&grade=${grade}`
          );
          if (response.data.status) {
            setSubjects(response.data.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching subjects:", error);
        }
      };

      fetchSubjects();
    }
  }, [board, grade]);

  // Fetch chapters from API
  // Update the fetchChapters function
  useEffect(() => {
    const fetchChapters = async () => {
      if (board && grade && subject) {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}&grade=${grade}&subject=${subject}`
          );
          if (response.data.status && response.data.data?.data) {
            const chaptersData = response.data.data.data.map(
              ([chapterNumber, chapterName]) => ({
                chapter_number: chapterNumber,
                chapter_name: chapterName,
              })
            );
            setAvailableChapters(chaptersData);
          }
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      }
    };

    fetchChapters();
  }, [board, grade, subject]);

  // Calculate allocated marks for a question set based on marking scheme
  const calculateAllocatedMarks = (questions) => {
    return {
      mcqs: (questions.mcqs || 0) * questionMarks.marks_of_each_mcqs,
      very_short:
        (questions.very_short_answers || 0) *
        questionMarks.marks_of_each_very_short_answers,
      short:
        (questions.short_answers || 0) *
        questionMarks.marks_of_each_short_answer,
      long:
        (questions.long_answers || 0) * questionMarks.marks_of_each_long_answer,
      very_long:
        (questions.very_long_answers || 0) *
        questionMarks.marks_of_each_very_long_answer,
      case_studies:
        (questions.case_studies || 0) *
        questionMarks.marks_of_each_case_studies,
      true_false:
        (questions.truth_False_questions || 0) *
        questionMarks.marks_of_each_truth_False_questions,
      fill_blanks:
        (questions.fill_in_the_blanks || 0) *
        questionMarks.marks_of_each_fill_in_the_blanks,
    };
  };

  // Calculate total allocated marks from a question set
  const sumAllocatedMarks = (allocatedMarks) => {
    return Object.values(allocatedMarks).reduce((sum, marks) => sum + marks, 0);
  };

  // Update allocated marks whenever questions or marking scheme changes
  useEffect(() => {
    // Calculate for chapters
    const newChapterAllocated = {};
    selectedChapters.forEach((chapter, index) => {
      const allocated = calculateAllocatedMarks(chapter.questions);
      newChapterAllocated[index] = allocated;
    });
    setChapterAllocatedMarks(newChapterAllocated);

    // Calculate for topics
    const newTopicAllocated = {};
    topics.forEach((topic, index) => {
      const allocated = calculateAllocatedMarks(topic.questions);
      newTopicAllocated[index] = allocated;
    });
    setTopicAllocatedMarks(newTopicAllocated);
  }, [selectedChapters, topics, questionMarks]);

  // Calculate total assigned marks
  const calculateTotalAssignedMarks = () => {
    const chapterMarks = selectedChapters.reduce(
      (sum, chapter) => sum + (Number(chapter.assigned_marks) || 0),
      0
    );
    const topicMarks = topics.reduce(
      (sum, topic) => sum + (Number(topic.assigned_marks) || 0),
      0
    );
    return chapterMarks + topicMarks;
  };

  // Update total assigned marks
  useEffect(() => {
    const newTotal = calculateTotalAssignedMarks();
    setTotalAssignedMarks(newTotal);
  }, [selectedChapters, topics]);

  // Helper function to check if allocated marks match assigned marks
  const isAllocatedMatchingAssigned = (index, type) => {
    const item = type === "chapter" ? selectedChapters[index] : topics[index];
    const allocatedMarks =
      type === "chapter"
        ? sumAllocatedMarks(chapterAllocatedMarks[index] || {})
        : sumAllocatedMarks(topicAllocatedMarks[index] || {});
    return Number(item.assigned_marks) === allocatedMarks;
  };
  // Helper function to format question type labels
  const formatQuestionTypeLabel = (questionType) => {
    if (questionType === "truth_False_questions") {
      return "TRUE/FALSE QUESTIONS";
    }
    return questionType.replace(/_/g, " ").toUpperCase();
  };
  // Render marks summary for chapters and topics
  const renderMarksSummary = () => (
    <Box
      sx={{
        position: "absolute",
        top: 16,
        right: 16,
        backgroundColor: "background.paper",
        padding: 2,
        borderRadius: 1,
        boxShadow: 1,
        minWidth: 200,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          mb: 1,
          color:
            totalMarks && totalMarks !== totalAssignedMarks
              ? "error.main"
              : "inherit",
        }}
      >
        Total Marks: {totalMarks || 0}
      </Typography>

      {selectedChapters.map((chapter, index) => (
        <Box key={`chapter-${index}`} sx={{ mb: 1 }}>
          <Typography
            variant="body2"
            sx={{
              color: !isAllocatedMatchingAssigned(index, "chapter")
                ? "error.main"
                : "inherit",
            }}
          >
            Assigned marks for Chapter {index + 1}:{" "}
            {chapter.assigned_marks || 0}
            <br />
            Allocated marks:{" "}
            {sumAllocatedMarks(chapterAllocatedMarks[index] || 0)}
          </Typography>
        </Box>
      ))}

      {topics.map((topic, index) => (
        <Box key={`topic-${index}`} sx={{ mb: 1 }}>
          <Typography
            variant="body2"
            sx={{
              color: !isAllocatedMatchingAssigned(index, "topic")
                ? "error.main"
                : "inherit",
            }}
          >
            Assigned marks for Topic {index + 1}: {topic.assigned_marks || 0}
            <br />
            Allocated marks:{" "}
            {sumAllocatedMarks(topicAllocatedMarks[index] || 0)}
          </Typography>
        </Box>
      ))}

      {totalMarks && totalMarks !== totalAssignedMarks && (
        <Typography
          variant="caption"
          color="error"
          sx={{ display: "block", mt: 1 }}
        >
          Total marks should match assigned marks
        </Typography>
      )}
    </Box>
  );

  // Function to add empty chapter
  const addChapter = () => {
    const newChapter = {
      chapter_number: "",
      chapter_name: "",
      assigned_marks: 0,
      questions: {
        mcqs: 0,
        very_short_answers: 0,
        short_answers: 0,
        long_answers: 0,
        very_long_answers: 0,
        case_studies: 0,
        truth_False_questions: 0,
        fill_in_the_blanks: 0,
      },
    };
    setSelectedChapters([...selectedChapters, newChapter]);
  };
  // Replace the chapters section in the JSX with this updated version
  const renderChaptersSection = () => (
    <>
      {selectedChapters.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" mb={2}>
            Chapters
          </Typography>

          {selectedChapters.map((chapter, index) => (
            <Box key={index} mb={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle1">{`Chapter ${
                  index + 1
                }`}</Typography>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    const newChapters = selectedChapters.filter(
                      (_, i) => i !== index
                    );
                    setSelectedChapters(newChapters);
                  }}
                >
                  Remove Chapter
                </Button>
              </Box>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select Chapter</InputLabel>
                <Select
                  value={chapter.chapter_number}
                  label="Select Chapter"
                  onChange={(e) => {
                    const selectedChapter = availableChapters.find(
                      (ch) => ch.chapter_number === e.target.value
                    );
                    const newChapters = [...selectedChapters];
                    newChapters[index] = {
                      ...newChapters[index],
                      chapter_number: selectedChapter.chapter_number,
                      chapter_name: selectedChapter.chapter_name,
                    };
                    setSelectedChapters(newChapters);
                  }}
                >
                  {availableChapters.map((chapterOption) => (
                    <MenuItem
                      key={chapterOption.chapter_number}
                      value={chapterOption.chapter_number}
                    >
                      {`Chapter ${chapterOption.chapter_number}: ${chapterOption.chapter_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Assigned Marks"
                fullWidth
                type="text"
                value={chapter.assigned_marks}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);

                  if (value >= 0 || e.target.value === "") {
                    const newChapters = [...selectedChapters];
                    newChapters[index].assigned_marks =
                      e.target.value === "" ? "" : value;
                    setSelectedChapters(newChapters);
                  }
                }}
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle1" mb={1}>
                Question Types
              </Typography>

              {/* Updated Question Types Layout */}
              <Grid container spacing={2}>
                {Object.keys(chapter.questions).map((questionType) => (
                  <Grid item xs={6} key={questionType}>
                    <TextField
                      label={formatQuestionTypeLabel(questionType)}
                      fullWidth
                      type="text"
                      value={chapter.questions[questionType]}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);

                        if (value >= 0 || e.target.value === "") {
                          const newChapters = [...selectedChapters];
                          newChapters[index].questions[questionType] =
                            e.target.value === "" ? "" : value;
                          setSelectedChapters(newChapters);
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Grid>
      )}
    </>
  );

  const addTopic = () => {
    setTopics([
      ...topics,
      {
        topic_name: "",
        assigned_marks: 0,
        questions: {
          mcqs: 0,
          very_short_answers: 0,
          short_answers: 0,
          long_answers: 0,
          very_long_answers: 0,
          case_studies: 0,
          true_False_questions: 0,
          fill_in_the_blanks: 0,
        },
        file: null,
      },
    ]);
  };

  // Update the handleShowTestStructure function
  const handleShowTestStructure = () => {
    console.log("Test Structure:", {
      board,
      grade,
      subject,
      testDuration,
      difficulty,
      totalMarks,
      chapters: selectedChapters,
      topics,
      questionMarks,
    });
  };

  const handleSubmit = async () => {
    const testData = {
      board: board || "N/A",
      grade: grade || "N/A",
      subject: subject || "N/A",
      test_duration: testDuration || 0,
      difficulty: difficulty || "N/A",
      total_marks: totalMarks || 0,
      topics: topics.map((topic) => ({
        topic_name: topic.topic_name || "N/A",
        assigned_marks: Number(topic.assigned_marks) || 0,
        questions: [
          {
            mcqs: Number(topic.questions.mcqs) || 0,
            very_short_answers: Number(topic.questions.very_short_answers) || 0,
            short_answers: Number(topic.questions.short_answers) || 0,
            long_answers: Number(topic.questions.long_answers) || 0,
            very_long_answers: Number(topic.questions.very_long_answers) || 0,
            case_studies: Number(topic.questions.case_studies) || 0,
            truth_False_questions:
              Number(topic.questions.truth_False_questions) || 0,
            fill_in_the_blanks: Number(topic.questions.fill_in_the_blanks) || 0,
          },
        ],
      })),
      chapters: selectedChapters.map((chapter) => ({
        chapter_number: chapter.chapter_number || "N/A",
        assigned_marks: Number(chapter.assigned_marks) || 0,
        questions: [
          {
            mcqs: Number(chapter.questions.mcqs) || 0,
            very_short_answers:
              Number(chapter.questions.very_short_answers) || 0,
            short_answers: Number(chapter.questions.short_answers) || 0,
            long_answers: Number(chapter.questions.long_answers) || 0,
            very_long_answers: Number(chapter.questions.very_long_answers) || 0,
            case_studies: Number(chapter.questions.case_studies) || 0,
            truth_False_questions:
              Number(chapter.questions.truth_False_questions) || 0,
            fill_in_the_blanks:
              Number(chapter.questions.fill_in_the_blanks) || 0,
          },
        ],
      })),
      marks_of_each_questions: {
        ...questionMarks,
        // Ensure all values are numbers
        marks_of_each_mcqs: Number(questionMarks.marks_of_each_mcqs),
        marks_of_each_very_short_answers: Number(
          questionMarks.marks_of_each_very_short_answers
        ),
        marks_of_each_short_answer: Number(
          questionMarks.marks_of_each_short_answer
        ),
        marks_of_each_long_answer: Number(
          questionMarks.marks_of_each_long_answer
        ),
        marks_of_each_very_long_answer: Number(
          questionMarks.marks_of_each_very_long_answer
        ),
        marks_of_each_case_studies: Number(
          questionMarks.marks_of_each_case_studies
        ),
        marks_of_each_truth_False_questions: Number(
          questionMarks.marks_of_each_truth_False_questions
        ),
        marks_of_each_fill_in_the_blanks: Number(
          questionMarks.marks_of_each_fill_in_the_blanks
        ),
      },
    };

    // Create FormData
    const formData = new FormData();
    formData.append("data", JSON.stringify(testData));

    // Append files from topics with renamed files
    topics.forEach((topic, i) => {
      if (topic.file) {
        // Get the file extension
        const fileExtension = topic.file.name.split(".").pop();

        // Create a new file with the topic name as the filename
        const renamedFile = new File(
          [topic.file],
          `${topic.topic_name.replace(/[^a-zA-Z0-9]/g, "_")}.${fileExtension}`,
          { type: topic.file.type }
        );

        formData.append(`files`, renamedFile);
      }
    });
    try {
      setLoading(true);

      // First, check internet connection
      if (!navigator.onLine) {
        throw new Error(
          "No internet connection. Please check your connection and try again."
        );
      }

      const response = await axios.post(
        "https://tpg-api.leoqui.ai/testpaper/test_paper_generator_topics_and_cbse_both",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
          // Add timeout
          timeout: 300000, // 30 seconds
          withCredentials: false,
          retry: 3,
          retryDelay: 1000,
        }
      );

      if (response.data.status === "success") {
        navigate("/test-plan-generator", {
          state: { TestPlanGeneratorV2: response.data },
        });
      } else {
        // Throw an error with the message from the API response
        throw new Error(
          response.data.message ||
            "Test plan could not be generated. Please try again."
        );
      }
    } catch (error) {
      console.error("Error submitting the form:", error);

      let errorMessage;

      if (!navigator.onLine) {
        errorMessage =
          "No internet connection. Please check your connection and try again.";
      } else if (error.code === "ECONNABORTED") {
        errorMessage = "Request timed out. Please try again.";
      } else if (error.response) {
        // Server responded with an error
        errorMessage =
          error.response.data?.message || "Server error. Please try again.";
      } else if (error.request) {
        // Request made but no response
        errorMessage = "No response from server. Please try again.";
      } else {
        // Fallback for other errors
        errorMessage =
          error.message ||
          "An error occurred while generating the lesson plan.";
      }

      // Display the specific error message
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <Modal
        open={open}
        onClose={() => {
          resetModal();
          onClose();
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          {renderMarksSummary()}
          <Typography variant="h5" component="h2" mb={3}>
            Exam Paper Generator V2
          </Typography>

          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth error={!board}>
                  <InputLabel>Board</InputLabel>
                  <Select
                    value={board}
                    label="Board"
                    onChange={(e) => setBoard(e.target.value)}
                  >
                    {boards.map((board, index) => (
                      <MenuItem key={index} value={board}>
                        {board}
                      </MenuItem>
                    ))}
                  </Select>
                  {!board && (
                    <span
                      style={{
                        color: "blue",
                        fontSize: "0.8rem",
                        marginTop: "5px",
                      }}
                    >
                      Please select a board first.
                    </span>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  error={!grade && board === ""}
                  disabled={!board}
                >
                  <InputLabel>Grade</InputLabel>
                  <Select
                    value={grade}
                    label="Grade"
                    onChange={(e) => setGrade(e.target.value)}
                  >
                    {grades.map((grade, index) => (
                      <MenuItem key={index} value={grade}>
                        {grade}
                      </MenuItem>
                    ))}
                  </Select>
                  {!board && (
                    <span
                      style={{
                        color: "blue",
                        fontSize: "0.8rem",
                        marginTop: "5px",
                      }}
                    >
                      First, choose a board.
                    </span>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  error={!subject && grade === ""}
                  disabled={!grade}
                >
                  <InputLabel>Subject</InputLabel>
                  <Select
                    value={subject}
                    label="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    {subjects.map((subject, index) => (
                      <MenuItem key={index} value={subject}>
                        {subject}
                      </MenuItem>
                    ))}
                  </Select>
                  {!grade && (
                    <span
                      style={{
                        color: "blue",
                        fontSize: "0.8rem",
                        marginTop: "5px",
                      }}
                    >
                      First, choose a grade.
                    </span>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Test Duration"
                type="text"
                value={testDuration}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);

                  if (value >= 0 || e.target.value === "") {
                    setTestDuration(e.target.value === "" ? "" : value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Difficulty</InputLabel>
                <Select
                  value={difficulty}
                  label="Difficulty"
                  onChange={(e) => setDifficulty(e.target.value)}
                >
                  <MenuItem value="Easy">Easy</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Hard">Hard</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Total Marks"
                type="text"
                value={totalMarks}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);

                  if (value >= 0 || e.target.value === "") {
                    setTotalMarks(e.target.value === "" ? "" : value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item>
                <Button variant="outlined" onClick={addChapter}>
                  + Chapter
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={addTopic}>
                  + Topic
                </Button>
              </Grid>
            </Grid>
            {/* Chapters Section */}
            {renderChaptersSection()}
            {/* Topics Section */}
            {topics.length > 0 && (
              <Grid item xs={12}>
                {topics.map((topic, index) => (
                  <Box key={index} mb={3}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="h6">Topic {index + 1}</Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          const newTopics = topics.filter(
                            (_, idx) => idx !== index
                          );
                          setTopics(newTopics);
                        }}
                      >
                        Remove Topic
                      </Button>
                    </Grid>
                    {/* Topic Name */}
                    <TextField
                      label="Topic Name"
                      fullWidth
                      value={topic.topic_name}
                      onChange={(e) => {
                        const newTopics = [...topics];
                        newTopics[index].topic_name = e.target.value;
                        setTopics(newTopics);
                      }}
                      sx={{ mb: 2 }}
                    />
                    {/* Assigned Marks */}
                    <TextField
                      label="Assigned Marks"
                      fullWidth
                      type="text"
                      value={topic.assigned_marks}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);

                        if (value >= 0 || e.target.value === "") {
                          const newTopics = [...topics];
                          newTopics[index].assigned_marks =
                            e.target.value === "" ? "" : value;
                          setTopics(newTopics);
                        }
                      }}
                      sx={{ mb: 2 }}
                    />
                    {/* File Upload */}
                    {/* File Upload */}
                    <Box sx={{ mb: 2 }}>
                      <input
                        type="file"
                        id={`file-upload-${index}`}
                        accept=".pdf"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          const newTopics = [...topics];
                          if (e.target.files[0]) {
                            newTopics[index].file = e.target.files[0];
                            setTopics(newTopics);
                          }
                        }}
                      />
                      <Typography variant="subtitle1" mb={1}>
                        Upload Reference File (Optional):
                      </Typography>
                      <label htmlFor={`file-upload-${index}`}>
                        <Button
                          variant="outlined"
                          component="span"
                          fullWidth
                          sx={{
                            textTransform: "none",
                            borderRadius: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                            padding: 2,
                          }}
                        >
                          <UploadIcon />
                          Browse Files (PDF Only)
                        </Button>
                      </label>
                      {topics[index].file && (
                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Selected File: {topics[index].file.name}
                          </Typography>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => {
                              const newTopics = [...topics];
                              newTopics[index].file = null;
                              setTopics(newTopics);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>

                    {/* Question Types */}
                    <Typography variant="subtitle1" mb={1}>
                      Question Types
                    </Typography>
                    <Grid container spacing={2}>
                      {[
                        "mcqs",
                        "very_short_answers",
                        "short_answers",
                        "long_answers",
                        "very_long_answers",
                        "case_studies",
                        "truth_False_questions",
                        "fill_in_the_blanks",
                      ].map((questionType) => (
                        <Grid item xs={6} key={questionType}>
                          <TextField
                            label={formatQuestionTypeLabel(questionType)}
                            fullWidth
                            type="text"
                            value={topic.questions[questionType] || 0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);

                              if (value >= 0 || e.target.value === "") {
                                const newTopics = [...topics];
                                newTopics[index].questions[questionType] =
                                  e.target.value === "" ? "" : value;
                                setTopics(newTopics);
                              }
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </Grid>
            )}

            {/* Marks of Each Question Section */}
            <Grid item xs={12}>
              <Typography variant="h6" mb={2}>
                Marks of Each Question
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of MCQ"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_mcqs}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_mcqs:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Very Short Answer"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_very_short_answers}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_very_short_answers:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Short Answer"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_short_answer}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_short_answer:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Long Answer"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_long_answer}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_long_answer:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Very Long Answer"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_very_long_answer}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_very_long_answer:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Case Studies"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_case_studies}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_case_studies:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of True/False Question"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_truth_False_questions}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_truth_False_questions:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Fill in the Blanks"
                    type="text"
                    fullWidth
                    value={questionMarks.marks_of_each_fill_in_the_blanks}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_fill_in_the_blanks:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              {/* Conditionally render the "Show Test Structure" button */}
              {false && (
                <Grid item>
                  <Button variant="contained" onClick={handleShowTestStructure}>
                    Show Test Structure
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Modal6;
